import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ProgressBar, Text } from '../../components';
import { downloadImage, IsEmpty } from '../../config/appConstant';
import './ESIMCardDetail.css';

const ESIMCardDetail = ({ title, content, ViewDetailClick, SendEmailClick, SendEmailLoading, listItems, buttonText, item, buttonLink }) => {
  console.log('item:', item)
  const { t } = useTranslation();

  return (
    <div className="eSIMCardDetail-mainContainer">
      <div className='eSIMCardDetail-LeftContainer'>
        <div className="eSIMCardDetail-title"><Text lineheightsmall bold primaryColor title5>{'ESIM :: ' + title}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('BundleDescription') + " : " + item.BundleDescription}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('ActivationCode') + " : " + item.ActivationCode}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('FirstInstallationDateTime') + " : " + item.FirstInstallationDateTime}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('SMDPAddress') + " : " + item.SMDPAddress}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('CountryName') + " : " + item.CountryName}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{t('IsBundleUsed') + " : " + item.IsBundleUsed}</Text></div>
        <div className="eSIMCardDetail-content tag-flex" ><Text lineheightsmall regular body2>{t("ProfileStatus")} : </Text> <div className={item.OrderStatus == 'Installed' ? 'emsimcard-tag-failure' : 'emsimcard-tag-success'} ><Text lineheightsmall whiteColor regular body2> { }</Text></div></div>
        <div className='eSIMCardDetail-content'>
          <Text lineheightsmall bold body2 PurpleColor>{(item.BundleType == 'UL' ? 'Unlimited Lite: ' : item.BundleType == 'UP' ? 'Unlimited Plus: ' : '')}</Text>
          <Text lineheightsmall regular body2>{item.BundleTypeDescription}</Text>
        </div>
        <div className='eSIMCardDetail-progress'>
          <Text lineheightmedium regular body2>{t("Validity") + ":"} </Text>
          <ProgressBar ProgressValue={item.ProgressPercentage} />
        </div>
        <div className='eSIMCardDetail-progress'>
          <Text lineheightmedium regular body2>{t("Used") + ":"} </Text>
          <ProgressBar ProgressValue={item.UsedDaysPercentage} />
        </div>
      </div>
      {!IsEmpty(item.QRCode) &&
        <div className='eSIMCardDetail-rightContainer'>
          <img src={item.QRCode} alt='Technolee' className='eSIMCardDetail-logo' />
          <div className='eSIMCardDetail-button-flex'>
            <Button onClick={() => ViewDetailClick(item)} full title={t('Add_More_Bundle')} />
            <Button onClick={() => SendEmailClick(item)} loading={SendEmailLoading} full title={t('Send_Email')} />
          </div>
          <div className='eSIMCardDetail-Qrbottomtext' >
            <Text bold>{t('Add_new_Bundle_description')}</Text>
          </div>
        </div>}

    </div>
  );
};

export default ESIMCardDetail;
