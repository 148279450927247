import React, { useState, useRef } from 'react';
import './Accordion.css';
import Arrowdown from '../../../assets/images/arrow_down.png';
import { Text } from '../../components';

const Accordion = ({ title, children, useTextComponent = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className={`accordion-header ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
        {useTextComponent ? (
          <div><Text bold blackColor>{title}</Text></div>
        ) : (<div className="accordion-title">{title}</div>)}

        <div className=""><img alt='down-arrow' src={Arrowdown} className='accordion-downarrow' /></div>
      </div>
      <div
        className="accordion-content"
        ref={contentRef}
        style={
          isOpen
            ? { maxHeight: contentRef.current.scrollHeight + 'px' }
            : { maxHeight: '0px' }
        }
      >
        <div className="accordion-inner-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
