import PropTypes from "prop-types";
import React from "react";
import { FontFamily, FontWeight, Typography, lineHeight, useTheme, } from "../../config";


export default function Index(props) {
  const { header, header1, heading2, heading, title1, title, title2, redColor, title3, title4, title5, headline, body1, body2, callout, subhead, footnote,
    caption1, caption2, overline, thin, ultraLight, light, regular, medium, semibold, bold, heavy,
    black, grayColor, dividerColor, whiteColor, ThirdButtonColor, SecoundButtonColor, BlackTextColor, garycardcolor, skycolor, mainButtonColor, bluetext, emaillinkcolor,
    fieldColor, blackColor, fontgreendark, textAlign, style, children, uppercase, capitalize, lowercase, DarkGrayTextColor, lightblueTextColor,
    lineheightreguler, lineheightmedium, lineheightsmall, primaryColor, lineheighthigh, darkgreen, mainColor, NaviBlueColor, PurpleColor } = props;
  const BaseColor = useTheme().theme.colors



  let textStyle = {
    fontFamily: FontFamily.FontFamily_1,
    textAlign: textAlign,
    color: BaseColor.text,
    ...header ? Typography.header : null,
    ...header1 ? Typography.header1 : null,
    ...heading ? Typography.heading : null,
    ...heading2 ? Typography.heading2 : null,
    ...title1 ? Typography.title1 : null,
    ...title ? Typography.title : null,
    ...title2 ? Typography.title2 : null,
    ...title3 ? Typography.title3 : null,
    ...title4 ? Typography.title4 : null,
    ...title5 ? Typography.title5 : null,
    ...headline ? Typography.headline : null,
    ...body1 ? Typography.body1 : null,
    ...body2 ? Typography.body2 : null,
    ...callout ? Typography.callout : null,
    ...subhead ? Typography.subhead : null,
    ...footnote ? Typography.footnote : null,
    ...caption1 ? Typography.caption1 : null,
    ...caption2 ? Typography.caption2 : null,
    ...overline ? Typography.overline : null,
    ...thin ? { fontWeight: FontWeight.thin } : null,
    ...ultraLight ? { fontWeight: FontWeight.ultraLight } : null,
    ...light ? { fontWeight: FontWeight.light } : null,
    ...regular ? { fontWeight: FontWeight.regular } : null,
    ...medium ? { fontWeight: FontWeight.medium } : null,
    ...semibold ? { fontWeight: FontWeight.semibold } : null,
    ...bold ? { fontWeight: FontWeight.bold } : null,
    ...heavy ? { fontWeight: FontWeight.heavy } : null,
    ...black ? { fontWeight: FontWeight.black } : null,
    ...uppercase ? { textTransform: 'uppercase' } : null,
    ...capitalize ? { textTransform: 'capitalize' } : null,
    ...lowercase ? { textTransform: 'lowercase' } : null,
    ...lineheightreguler ? { lineHeight: lineHeight.regular } : null,
    ...lineheightsmall ? { lineHeight: lineHeight.small } : null,
    ...lineheightmedium ? { lineHeight: lineHeight.medium } : null,
    ...lineheighthigh ? { lineHeight: lineHeight.height } : null,
    ...grayColor ? { color: BaseColor.grayColor } : null,
    ...dividerColor ? { color: BaseColor.dividerColor } : null,
    ...whiteColor ? { color: BaseColor.whiteColor } : null,
    ...fontgreendark ? { color: BaseColor.fontgreendark } : null,
    ...primaryColor ? { color: BaseColor.mainColor } : null,
    ...bluetext ? { color: BaseColor.bluetext } : null,
    ...fieldColor ? { color: BaseColor.fieldColor } : null,
    ...blackColor ? { color: BaseColor.blackColor } : null,
    ...ThirdButtonColor ? { color: BaseColor.ThirdButtonColor } : null,
    ...mainButtonColor ? { color: BaseColor.mainButtonColor } : null,
    ...SecoundButtonColor ? { color: BaseColor.SecoundButtonColor } : null,
    ...BlackTextColor ? { color: BaseColor.BlackTextColor } : null,
    ...garycardcolor ? { color: BaseColor.garycardcolor } : null,
    ...skycolor ? { color: BaseColor.skycolor } : null,
    ...redColor ? { color: BaseColor.redColor } : null,
    ...emaillinkcolor ? { color: BaseColor.emaillinkcolor } : null,
    ...darkgreen ? { color: BaseColor.darkgreen } : null,
    ...mainColor ? { color: BaseColor.mainColor } : null,
    ...NaviBlueColor ? { color: BaseColor.NaviBlueColor } : null,
    ...PurpleColor ? { color: BaseColor.PurpleColor } : null,
    ...DarkGrayTextColor ? { color: BaseColor.DarkGrayTextColor } : null,
    ...lightblueTextColor ? { color: BaseColor.lightblueTextColor } : null,

    ...style ? style : null,
  }

  return (
    <span style={textStyle}>
      {children}
    </span>
  );
}

Index.propTypes = {
  header: PropTypes.bool,
  header1: PropTypes.bool,
  heading: PropTypes.bool,
  heading2: PropTypes.bool,
  title1: PropTypes.bool,
  title2: PropTypes.bool,
  title3: PropTypes.bool,
  title4: PropTypes.bool,
  title5: PropTypes.bool,
  headline: PropTypes.bool,
  body1: PropTypes.bool,
  body2: PropTypes.bool,
  callout: PropTypes.bool,
  subhead: PropTypes.bool,
  footnote: PropTypes.bool,
  caption1: PropTypes.bool,
  caption2: PropTypes.bool,
  overline: PropTypes.bool,
  thin: PropTypes.bool,
  ultraLight: PropTypes.bool,
  light: PropTypes.bool,
  regular: PropTypes.bool,
  medium: PropTypes.bool,
  mediumsemibold: PropTypes.bool,
  lineheighthigh: PropTypes.bool,
  lineheightmedium: PropTypes.bool,
  lineheightreguler: PropTypes.bool,
  lineheightsmall: PropTypes.bool,
  bold: PropTypes.bool,
  heavy: PropTypes.bool,
  black: PropTypes.bool,
  primaryColor: PropTypes.bool,
  darkPrimaryColor: PropTypes.bool,
  lightPrimaryColor: PropTypes.bool,
  accentColor: PropTypes.bool,
  grayColor: PropTypes.bool,
  ThirdButtonColor: PropTypes.bool,
  SecoundButtonColor: PropTypes.bool,
  redColor: PropTypes.bool,
  BlackTextColor: PropTypes.bool,
  garycardcolor: PropTypes.bool,
  skycolor: PropTypes.bool,
  dividerColor: PropTypes.bool,
  whiteColor: PropTypes.bool,
  bluetext: PropTypes.bool,
  fieldColor: PropTypes.bool,
  blackColor: PropTypes.bool,
  mainColor: PropTypes.bool,
  NaviBlueColor: PropTypes.bool,
  PurpleColor: PropTypes.bool,
  DarkGrayTextColor: PropTypes.bool,
  lightblueTextColor: PropTypes.bool,

  emaillinkcolor: PropTypes.bool,
  fontgreendark: PropTypes.bool,
  mainButtonColor: PropTypes.bool,
  numberOfLines: PropTypes.number,
  textAlign: PropTypes.string,
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node,
};

Index.defaultProps = {
  header: false,
  header1: false,
  heading: false,
  heading2: false,
  title1: false,
  title2: false,
  title3: false,
  title4: false,
  title5: false,
  headline: false,
  body1: false,
  body2: false,
  callout: false,
  subhead: false,
  footnote: false,
  caption1: false,
  caption2: false,
  overline: false,
  thin: false,
  ultraLight: false,
  light: false,
  regular: false,
  medium: false,
  semibold: false,
  bold: false,
  heavy: false,
  black: false,
  primaryColor: false,
  darkPrimaryColor: false,
  lightPrimaryColor: false,
  accentColor: false,
  mainColor: false,
  NaviBlueColor: false,
  PurpleColor: false,

  dividerColor: false,
  whiteColor: false,
  fieldColor: false,
  blackColor: false,
  darkgreen: false,
  ThirdButtonColor: false,
  SecoundButtonColor: false,
  redColor: false,
  BlackTextColor: false,
  garycardcolor: false,
  skycolor: false,
  mainButtonColor: false,
  fontgreendark: false,
  emaillinkcolor: false,
  bluetext: false,
  numberOfLines: 10,
  textAlign: "left",
  style: {},
  children: "",
  capitalize: false,
  uppercase: false,
  lowercase: false,
  lineheighthigh: false,
  lineheightmedium: false,
  lineheightreguler: false,
  lineheightsmall: false,

};
