import React from 'react';
import './TagButton.css';
import { Text } from "../../components";
import { useTranslation } from 'react-i18next';
import { Images } from '../../config';

const labelsData = [
  { iconSrc: Images.Group_ic, text: 'TagButton_lable1' },
  { iconSrc: Images.Smile_ic, text: 'TagButton_lable2' },
  { iconSrc: Images.shield_ic, text: 'TagButton_lable3' },
  { iconSrc: Images.Dollar_ic, text: 'TagButton_lable4' },
];

const TagButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (

    <div className='Tagbutton-main'>
      {labelsData.map((dest, index) => (
        <button key={index} className="label-container" onClick={onClick}>
          <div className="label-icon" >
            <img src={dest.iconSrc} alt="icon-tag" style={{ width: '100%', height: "100%", overflow: "auto" }}/>
          </div>
          <div className="label-text"> <Text medium PurpleColor subhead >{t(dest.text)}</Text> </div>
        </button>
      ))}
    </div>

  );
};

export default TagButton;
