import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CommonModal, Text } from '../../components';
import { useTheme } from '../../config';
import { formatCurrency, IsEmpty } from '../../config/appConstant';
import "./DetailsModal.css";

const formatDataAmount = (dataInMB) => {
    if (dataInMB === -1) {
        return 'Unlimited';
    }
    const dataInGB = dataInMB / 1000;
    return dataInGB % 1 === 0 ? `${dataInGB} GB` : `${dataInGB.toFixed(2)} GB`;
};


const DetailsModal = ({ bundle, handeBundleSelect, setDetailsModalOpen }) => {
    const { t } = useTranslation();
    const BaseColor = useTheme().theme.colors
    const [networkModalOpen, setNetworkModalOpen] = useState(false);
    const [roamingModalOpen, setRoamingModalOpen] = useState(false);

    return (
        <>
            <div style={{ width: "100%" }} >
                <div className='bg-light-pink'>
                    <div className='row'>
                        <div className='row-right'>
                            <div className='white-box'>
                                <Text bold title>{t("Plan_Details")}</Text>
                                <div className='data-plan-box'>
                                    <div className='plan-name'>
                                        <div>
                                            <img className='c-icon' src={bundle.CountryImage} alt="Country-flag" width={60} />
                                        </div>
                                        <div>
                                            <Text title3>{bundle.CountryName}</Text>
                                        </div>
                                    </div>
                                </div>
                                <ul className='detailed-list'>
                                    <li>
                                        <Text headline bold>{t("Validity")}</Text>
                                        <Text regular headline >{bundle.Duration} {t("Days")}</Text>
                                    </li>
                                    <li>
                                        <Text headline bold>{t("Data_Amount")}</Text>
                                        <Text regular headline >{formatDataAmount(bundle.DataAmount)}</Text>
                                    </li>
                                    <li>
                                        <Text headline bold>{t("Calls_Texts")}</Text>
                                        <Text regular headline >{bundle.CallsAndText}</Text>
                                    </li>
                                    <li>
                                        <Text headline bold>{t("Coverage")}</Text>
                                        <Text regular headline >{bundle.CountryName}</Text>
                                    </li>
                                </ul>
                                <Text title3 bold>{t("Activation")}</Text><br />
                                <Text headline regular >{bundle.ActivationText}</Text>
                            </div>
                        </div>
                        <div className='row-left'>
                            <div className='white-box'>
                                <Text bold title>{t("Additional_Details")}</Text>
                                <ul className='detailed-list'>
                                    {!IsEmpty(bundle.Networks) && (
                                        <li>
                                            <Text headline bold>{t("Network")}</Text>
                                            <div className='view-click' onClick={() => setNetworkModalOpen(true)}> <Text regular mainColor headline style={{ borderBottom: '1px solid', borderColor: BaseColor.mainColor }}>{t("View")}</Text></div>
                                        </li>
                                    )}
                                    {!IsEmpty(bundle.roamingEnabled) && (
                                        <li>
                                            <Text headline bold>{t("Roaming_Country")}</Text>
                                            <div className='text-black view-click' onClick={() => setRoamingModalOpen(true)}><Text mainColor style={{ borderBottom: '1px solid', borderColor: BaseColor.mainColor }} headline bold>{t("View")}</Text></div>
                                        </li>
                                    )}
                                    <li>
                                        <Text headline bold>{t("Plan_Type")}</Text>
                                        <Text headline regular>{bundle.PlanType}</Text>
                                    </li>
                                    <li>
                                        <Text headline bold>{t("Speed")}</Text>
                                        <Text headline regular>{bundle.BundleSpeed}</Text>

                                    </li>
                                    {!IsEmpty(bundle.BundleTypeDescription) && (
                                        <li>
                                            <Text headline bold>{(bundle.BundleType == 'UL' ? 'Unlimited Lite: ' : bundle.BundleType == 'UP' ? 'Unlimited Plus: ' : '')}</Text>
                                            <Text headline regular>{bundle.BundleTypeDescription}</Text>
                                        </li>)}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div style={{ display: "flex", width: "100%" }}>

                        <div className='modal-footer-left'>
                            <div className='heading-three'><Text bold title1>{formatCurrency(bundle.PriceTotal)}</Text></div>
                        </div>
                        <div className='modal-footer-right'>
                            <div className='button-buy-now'>
                                <Button onClick={() => {
                                    handeBundleSelect(bundle);
                                    setDetailsModalOpen(false);
                                }} round capsule type={2} full title={'Buy Now'} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <CommonModal IsScroll={true} maxWidth={'md'} onClose={() => setNetworkModalOpen(false)} visible={networkModalOpen} >
                <div className='network-modal-content'>
                    <div className='network-heading'><Text title5 bold>{t("Available_Networks")}</Text></div>
                    <div className='network-list'>
                        {bundle.Networks.map((network, index) => (
                            <div key={index} className='network-item'>
                                <Text bold whiteColor>{network.NetworkName}</Text>
                            </div>
                        ))}
                    </div>
                </div>
            </CommonModal>
            <CommonModal IsScroll={false} maxWidth={'md'} onClose={() => setRoamingModalOpen(false)} visible={roamingModalOpen} >
                <div>
                    <div className='Roaming-main'>
                        <div className='roaming-heading'>
                            <Text title1 PurpleColor bold >{t("Roaming_Country")}</Text>
                        </div>
                        <div className='roaming-flex-wrap' >
                            {bundle.roamingEnabled.map((country, idx) => (
                                <div key={idx} className='roaming-country'>
                                    <Text title1>{country.CountryEmoji}</Text>
                                    <Text whiteColor>{country.CountryName} </Text>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </CommonModal>

        </>

    )
}
export default DetailsModal;
