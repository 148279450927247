import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonModal, Text } from '../../components';
import { onLogout } from '../../config/appConstant';
import { AiOutlineLogout } from 'react-icons/ai';
import { MdAccountCircle, MdEdit, MdDelete, MdLogin } from 'react-icons/md';
import EditProfile from '../../screens/EditProfile/EditProfile';
import * as APIController from "../../API/APIController";
import { toast } from 'react-toastify';
import { IsEmpty } from '../../config/appConstant';
import './navbar.css';
import { BaseColor } from '../../config';
import { useTranslation } from 'react-i18next';
import { setLoginModal } from '../../redux/store';

function UserActionsDropdown() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userdetail);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1040) {
        setIsSidebar(true);
      } else {
        setIsSidebar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Set initial state on load
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOpenEditProfile = () => {
    setOpenEditProfile(true);
  };

  const handleOpenDeleteAccount = () => {
    setDeleteAccount(true);
  };

  const wsCustomerGetOffline = (emailAddress, customerCode) => {
    APIController.CustomerGetOffline(emailAddress, customerCode)
      .then((response) => {
        if (IsEmpty(response.error)) {
          if (response.object.Status === 1) {
            toast.success(response.object.Message);
            onLogout();
          } else {
            toast.error(response.object.Message);
          }
        }
      }).catch((err) => {
        console.log('err:', err);
      });
  };

  const handleDeleteAccount = () => {
    wsCustomerGetOffline(userDetails.EmailID, userDetails.CC);
  };

  return (
    <>
      {isSidebar ? (
        <div style={{ borderTop: '1px solid', borderRadius: '0px', borderTopColor: BaseColor.mainColor }} className={`sidebar ${isOpen ? 'open' : ''}`}>
          <div className="sidebar-menu">
            <div onClick={handleOpenEditProfile} className="dropdown-item">
              <MdEdit style={{ marginRight: 10, fontSize: 20 }} />
              <Text title3 bold>{t('Edit_Profile')}</Text>
            </div>
            <div onClick={handleOpenDeleteAccount} className="dropdown-item">
              <MdDelete style={{ marginRight: 10, fontSize: 20 }} />
              <Text title3 bold>{t('Delete_Account')}</Text>
            </div>
            <div onClick={onLogout} className="dropdown-item">
              <AiOutlineLogout style={{ marginRight: 10, fontSize: 20 }} />
              <Text title3 bold>{t('Logout')}</Text>
            </div>
          </div>
        </div>
      ) : (
        <div className="dropdown Profile-menu">
          <div className="profile-icon" onClick={toggleDropdown}>
            <MdAccountCircle style={{ color: 'gray', fontSize: 50 }} />
          </div>
          {isOpen && (
            <div className="dropdown-menu">

              <div onClick={handleOpenEditProfile} className="dropdown-item">
                <MdEdit style={{ marginRight: 10, fontSize: 20 }} />
                <Text>{t('Edit_Profile')}</Text>
              </div>
              <div onClick={handleOpenDeleteAccount} className="dropdown-item">
                <MdDelete style={{ marginRight: 10, fontSize: 20 }} />
                <Text>{t('Delete_Account')}</Text>
              </div>
              <div onClick={onLogout} className="dropdown-item">
                <AiOutlineLogout style={{ marginRight: 10, fontSize: 20 }} />
                <Text>{t('Logout')}</Text>
              </div>

            </div>
          )}
        </div>
      )}

      {/* Modals for Edit Profile and Delete Account */}
      <CommonModal
        IsScroll={true}
        maxWidth={'xl'}
        onClose={() => setOpenEditProfile(false)}
        visible={openEditProfile}
      >
        <EditProfile onClose={() => setOpenEditProfile(false)} />
      </CommonModal>
      <CommonModal
        IsScroll={true}
        IsDeletePopup={true}
        Title={'Delete this account'}
        onSave={handleDeleteAccount}
        maxWidth={'xl'}
        ActionText={'ok'}
        onClose={() => setDeleteAccount(false)}
        visible={deleteAccount}
      />
    </>
  );
}

export default UserActionsDropdown;
