import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components';
import { Images } from '../../config';
import './Installationstep.css';

const steps = [
    { number: "01", title: "Intallation_stepone", description: "Intallation_stepone_description", description1: "Intallation_description" },
    { number: "02", title: "Intallation_steptwo", description: "Intallation_steptwo_description", description1: "Intallation_description" },
    { number: "03", title: "Intallation_stepthree", description: "Intallation_stepthree_description", description1: "Intallation_description" },
];


const Installationstep = () => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(1);
    const [flipClass, setFlipClass] = useState('');

    const stepImages = {
        1: Images.Phone3,
        2: Images.Phone2,
        3: Images.Phone1,
    };

    // Function to handle scroll event
    const handleScroll = () => {
        const stepsSection = document.getElementById('Installationstep-steps');
        const stepsPosition = stepsSection.getBoundingClientRect();
        const stepHeight = stepsSection.offsetHeight / steps.length;
        const stepIndex = Math.floor((stepsPosition.top + stepsPosition.height / 1) / stepHeight);

        if (stepIndex >= 1 && stepIndex <= 3) {
            // Trigger flip animation only when the step changes
            if (currentStep !== stepIndex) {
                setFlipClass('flipped'); // Trigger the flip animation
                setTimeout(() => {
                    setCurrentStep(stepIndex); // Update the current step after flip animation
                    setFlipClass(''); // Reset flip class after animation is done
                }, 400); // Wait for the animation to finish before updating step
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentStep]); // Add currentStep to dependencies to trigger re-render when step changes

    return (
        <div className='Installationstep-main'>
            <div className="Installationstep-textheader">
                <Text bold mainColor heading >Getting Started</Text>
            </div>

            <div className='row-new'>
                <div className='row-left col-xl-5 col-md-6 d-none d-md-block'>
                    <div className='get-started-left-bl'>
                        <div className='get-started-img-block'>
                            <div className='get-started-img'>
                                <div className={`flip-container ${flipClass}`}>
                                    <img
                                        className="front"
                                        src={stepImages[currentStep]}
                                        alt="Phone"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row-right col-xl-6 offset-xl-1 col-md-6 Installationstep-steps' id="Installationstep-steps">
                    {steps.map((step, index) => (
                        <React.Fragment key={index}>
                            <div className="Installationstep-circle" style={{ top: `${210 + index * 420}px` }}></div>
                            <div className="Installationstep-border"></div>
                            <div className="Installationstep-step">
                                <div className="Installationstep-step-content" style={{ margin: "auto 0px" }}>
                                    <div className="number"><Text heavy DarkGrayTextColor lineheightsmall header >{step.number}</Text></div>
                                    <div className="text"><Text bold lineheightsmall mainColor title4 >{t(step.title)}</Text></div>
                                    <div className='para'><Text bold lineheightmedium body1 >{t(step.description)}</Text></div>
                                    <div className='para' style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                        <div><img width={15} src={Images.Group} alt="icon" /></div>
                                        <div><Text lineheightmedium bold subhead >{t(step.description1)}</Text></div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Installationstep;
