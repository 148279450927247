import React from 'react';
import './SingleCheckBox.css'; // Make sure this file is properly imported
import { IsEmpty } from '../../config/appConstant';

export default function SingleCheckBox(props) {
    const { type = "checkbox", label, name, checked, id, onChange, isDisabled, IslabelShow } = props;

    return (
        <div className="custom-checkbox">
            {/* Input checkbox hidden, only label is styled */}
            <input
                disabled={isDisabled}
                id={id}
                type={type}
                name={name}
                checked={checked}
                onChange={onChange}
            />
            {/* Label for custom checkbox with animation */}
            {!IsEmpty(IslabelShow) && (
                <label
                    htmlFor={id}
                    style={{
                        marginLeft: '0px',
                        fontWeight: checked ? 600 : 400,
                    }}
                >
                    {label}
                </label>
            )}
        </div>
    );
}
