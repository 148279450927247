// src/Footer.js

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components';
import { Images } from '../../config';
import { AppConstant, getCurrentYear } from '../../config/appConstant';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [touched, setTouched] = useState(false);

  return (
    <footer className="footer-main-container">
      <div className="footer-container">
        <div className="footer-section logo">
          <img src={Images.mainlogo} alt="main-Logo" />
          <div className='logo-text' style={{ display: "none" }}>
            <Text lineheightsmall caption2>Lorem ipsum dolor sit amet consectetur. Sit
              malesuada maecenas lectus malesuada dui
              auctor natoque dui. Morbi congue sociis
              hac velit. Est ultricies nulla et id.</Text>
          </div>
          <div className="social-icons">
            <a href="https://x.com/e_Simlife" target="_blank" aria-label="twitter" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61566480296287" aria-label="facebook" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/esimlife/" target="_blank" aria-label="instagram" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@esimlife" target="_blank" aria-label="tiktok" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
          </div>
        </div>
        <div className="footer-section about">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_one')}</Text></div>
          <ul>
            <li><a aria-label="About Us" href="/aboutus">{t('About Us')}</a></li>
            <li><a aria-label="Contact Us" href="/contactus">{t('Contact Us')}</a></li>
            <li><a aria-label="Get Help" href="/gethelp">{t('Get Help')}</a></li>
            <li><a aria-label="My eSIM" href="/myeSIM" >{t('My eSIM')}</a></li>
          </ul>
        </div>
        <div className="footer-section links">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_two')}</Text></div>
          <ul>
            <li><a aria-label="Terms and Condition" href="/termsandcondition">{t('Terms and Condition')}</a></li>
            <li><a aria-label="Privacy Policy" href="/privacypolicy">{t('Privacy Policy')}</a></li>
            <li><a aria-label="Right to delete" href="/righttodelete">{t('Right to delete')}</a></li>
            <li><a aria-label="Right to delete" href="/faq">{t('FAQ')}</a></li>

          </ul>
        </div>
        <div className="footer-section ">
          <div className='footer-heading-text'><Text lineheighthigh title>{t('footer_heading_three')}</Text></div>
          <a aria-label="email" tyle={{ textDecoration: "none" }} href="mailto:info@e-simlife.com"><p>info@e-simlife.com </p></a>
        </div>
      </div>
      <div className="footer-bottom">
        <Text lineheighthigh bold DarkGrayTextColor>© {getCurrentYear() + " " + AppConstant.FooterCopyRigthtText}</Text>
      </div>
    </footer>
  );
};

export default Footer;
