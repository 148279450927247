import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "..";
import { bytesToSize } from "../../config/appConstant";
import "./FilterModal.css";

const FilterModal = ({ DataAmount, DurationData, OnapplyFilter }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [DataAmounts, setDataAmounts] = useState(DataAmount);
  const [DurationDatas, setDurationDatas] = useState(DurationData);
  const [reload, setreload] = useState(false);
  const [selectetedfilter, setselectetedfilter] = useState({
    "Data": '',
    "Duration": ''
  })

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const OnclearFilter = () => {
    setselectetedfilter({ ...selectetedfilter, Data: '', Duration: '' })
    setreload(!reload)

  };

  const OnClickFliter = (item, type) => {
    type == 1 ? selectetedfilter.Data = item : selectetedfilter.Duration = item
    setselectetedfilter(selectetedfilter)
    setreload(!reload)
  }

  useEffect(() => {
    setDataAmounts(DataAmount)
    setDurationDatas(DurationData)
  }, [reload])

  return (
    <div className="filter-container">
      <Button round type={5} onClick={toggleModal} title={t('Apply_Filters')} />
      {/* Modal */}
      <div className={`filter-modal ${isModalOpen ? "open" : ""}`}>
        <div className="FilterModal-header">
          <Text whiteColor bold>{t("Filters")}</Text>
          <button className="close-button" onClick={toggleModal}>
            &times;
          </button>
        </div>
        <div className="FilterModal-content">
          <div className="filter-section">

            <Text lineheighthigh bold >{t("Duration")}</Text>
            <div className="FilterModal-filterdiv">
              {DataAmounts?.map((item) => {
                return (
                  <div onClick={() => OnClickFliter(item, 1)} className={item == selectetedfilter.Data ? "FilterModal-filterbuttonSeleteted" : "FilterModal-filterbutton"} >
                    <Text whiteColor bold footnote textAlign={'center'}>
                      {item == -1 ? ('Unlimited') : bytesToSize(item * 1000000)}
                    </Text>
                  </div>)
              })}
            </div>
          </div>
          <div className="filter-section">

            <Text lineheighthigh bold>{t("Data_Amount")}</Text>
            <div className="FilterModal-filterdiv">
              {DurationDatas?.map((item) => {
                return (
                  <div onClick={() => OnClickFliter(item, 2)} className={item == selectetedfilter.Duration ? "FilterModal-filterbuttonSeleteted" : "FilterModal-filterbutton"}>
                    <Text whiteColor bold footnote textAlign={'center'}>{item + (item > 1 ? ' Days' : ' Day')}</Text>
                  </div>)
              })}
            </div>
          </div>
        </div>
        <div className="FilterModal-footer">
          <Button type={6} onClick={() => (OnapplyFilter(selectetedfilter), setIsModalOpen(false))} title={t('Apply')} />
          <Button type={5} onClick={() => OnclearFilter()} title={t('Clear')} />
        </div>
      </div>

      {/* Overlay */}
      {isModalOpen && <div className="overlay" onClick={toggleModal}></div>}
    </div >
  );
};

export default FilterModal;
